<template>
  <section id="FordPlazaMobile">
    <SideBarNavMobile subsectionTitle="Ford Plaza" />
    <div class="mobile-overlay"></div>
    <Photos />
    <Layout />
  </section>
</template>

<script>
  import Photos from '@/components/sections/EventSpaces/FordPlaza/Photos'
  import Layout from '@/components/sections/EventSpaces/FordPlaza/Layout'
  import SideBarNavMobile from '@/components/Nav/SideBarNavMobile'

  export default {
    name: "TwitchLoungeMobile",
    components: {
      Photos,
      Layout,
      SideBarNavMobile
    }
  }
</script>

<style lang="scss" scoped>
  #FordPlazaMobile {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background-image: url('~@/assets/images/MobileBG/Ford-Plaze-BG.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    margin-top: -60px;
    .mobile-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(270deg, rgba(0,0,0,0.83) 20%, rgba(0,0,0,0.92) 82%);
    }
  }
</style>